import React, {FC, useEffect, useState} from 'react'
import {icons} from './CardDetails'
import {CardInfo, OrderDetail, SocialLink} from './interfaces/cardDetails'
import {useLocation} from 'react-router-dom'
import {
  CARD_BG_IMAGE_URL,
  COVER_IMAGE_URL,
  PROFILE_IMAGE_URL,
  SOCIAL_MEDIA_URL,
} from '../../modules/helpers/imageUrl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {UpdateDashboardAPI} from '../customization/savedTemplates/updateDashboardAPI'
interface PropsChild {
  id?: any
  cardDetailsData?: CardInfo
  orderDetails?: OrderDetail
}

interface Contact {
  firstName: string | undefined
  middleName: string
  lastName: string | undefined
  country_code: string
  phone: string | undefined
  whatsapp: string | undefined
  alternatePhone: string | undefined
  email: string | undefined
  company: string | undefined
  designation: string | undefined
  address: string
  photoURL: string
  url: string | undefined
  facebook: string | undefined
  instagram: string | undefined
}

const coverImageUrl = COVER_IMAGE_URL
const profileImageUrl = PROFILE_IMAGE_URL
const socialMediaURL = SOCIAL_MEDIA_URL
const backgroundImgUrl = CARD_BG_IMAGE_URL

const CardDetails3: FC<PropsChild> = ({id, cardDetailsData, orderDetails}) => {
  const [cardID,setCardID] = useState<number | any>()
  const styleconfig = orderDetails?.template
  const socialMedia = cardDetailsData?.social_links!
  const location = useLocation()
  const [vcard, setvcard] = useState<string>('')
  const [contact, setContact] = useState<Contact>({
    firstName: '',
    middleName: '',
    lastName: '',
    country_code: '',
    phone: '',
    whatsapp: '',
    alternatePhone: '',
    email: '',
    company: '',
    designation: '',
    address: '',
    photoURL: '',
    url: '',
    facebook: '',
    instagram: '',
  })

  const getContact = async () => {
    const contact = {
      firstName: cardDetailsData?.first_name,
      middleName: cardDetailsData?.middle_name ? cardDetailsData?.middle_name : '',
      lastName: cardDetailsData?.last_name ? cardDetailsData?.last_name : '',
      country_code: cardDetailsData?.country_code ? cardDetailsData.country_code : '',
      phone: cardDetailsData?.mobile_no,
      whatsapp: cardDetailsData?.whats_app_no ? cardDetailsData.whats_app_no : '',
      alternatePhone: cardDetailsData?.alternet_no ? cardDetailsData.alternet_no : '',
      email: cardDetailsData?.email,
      company: cardDetailsData?.company_name ? cardDetailsData?.company_name : '',
      designation: cardDetailsData?.designation ? cardDetailsData.designation : '',
      address:
        cardDetailsData?.address?.length! > 0
          ? cardDetailsData?.address?.at(0)?.address_line1! +
            (cardDetailsData?.address?.at(0)?.city
              ? ' ' + cardDetailsData?.address?.at(0)?.city
              : '') +
            (cardDetailsData?.address?.at(0)?.country
              ? ' ' + cardDetailsData?.address?.at(0)?.country
              : '') +
            (cardDetailsData?.address?.at(0)?.pin_code
              ? ' ' + cardDetailsData?.address?.at(0)?.pin_code
              : '')
          : '',
      photoURL: profileImageUrl + styleconfig?.profile_image,
      url: cardDetailsData?.website_link ? cardDetailsData?.website_link : '',
      facebook: cardDetailsData?.facebook ? cardDetailsData.facebook : '',
      instagram: cardDetailsData?.instagram ? cardDetailsData.instagram : '',
    }

    setContact(contact)
    setCardID(cardDetailsData?.id)
  }

  async function whatsapp_delivery()
   {

    await UpdateDashboardAPI('whatsapp', Number(cardDetailsData?.id)!)
    window.open(`https://api.whatsapp.com/send?phone=${cardDetailsData?.country_code}${cardDetailsData?.whats_app_no}&text=Hello%20there!`, '_blank')

  }

  const handleDownloadContact = async () => {

    const contact = {
      firstName: cardDetailsData?.first_name,
      middleName: cardDetailsData?.middle_name ? cardDetailsData?.middle_name : '',
      lastName: cardDetailsData?.last_name ? cardDetailsData?.last_name : '',
      country_code: cardDetailsData?.country_code ? cardDetailsData.country_code : '',
      phone: cardDetailsData?.mobile_no,
      whatsapp: cardDetailsData?.whats_app_no ? cardDetailsData.whats_app_no : '',
      alternatePhone: cardDetailsData?.alternet_no ? cardDetailsData.alternet_no : '',
      email: cardDetailsData?.email,
      company: cardDetailsData?.company_name?cardDetailsData?.company_name:'' ,
      designation: cardDetailsData?.designation ? cardDetailsData.designation : '',
      address:
        cardDetailsData?.address?.length! > 0
          ? cardDetailsData?.address?.at(0)?.address_line1! +
            (cardDetailsData?.address?.at(0)?.city
              ? ' ' + cardDetailsData?.address?.at(0)?.city
              : '') +
            (cardDetailsData?.address?.at(0)?.country
              ? ' ' + cardDetailsData?.address?.at(0)?.country
              : '') +
            (cardDetailsData?.address?.at(0)?.pin_code
              ? ' ' + cardDetailsData?.address?.at(0)?.pin_code
              : '')
          : '',
      photoURL: profileImageUrl + styleconfig?.profile_image,
      url: cardDetailsData?.website_link ? cardDetailsData?.website_link : '',
      facebook: cardDetailsData?.facebook ? cardDetailsData.facebook : '',
      instagram: cardDetailsData?.instagram ? cardDetailsData.instagram : '',
    }
  
    const vcard = `BEGIN:VCARD
VERSION:3.0
PRODID:-//Apple Inc.//Mac OS X 10.14.1//EN
N:${contact.lastName};${contact.firstName};${contact.middleName};;;
FN:${contact.firstName} ${contact.middleName} ${contact.lastName}
ORG:${contact.company}
TEL;TYPE=CELL;TYPE=VOICE:${contact.country_code}${contact.phone}
TEL;TYPE=CELL;TYPE=VOICE:${contact.country_code}${contact.alternatePhone}
TEL;TYPE=WHATSAPP:${contact.country_code}${contact.whatsapp}
EMAIL:${contact.email}
ADR;TYPE=WORK:;;${contact.address};;;;
TITLE:${contact.designation}
URL:${contact.url}
URL;TYPE=cardUrl:${'https://card.tap-savvy.com' + location.pathname}
URL;TYPE=facebook:${contact.facebook}
URL;TYPE=instagram:${contact.instagram}

END:VCARD`

    const vcardBlob = new Blob([vcard], {type: 'text/vcard'})
    const vcardURL = URL.createObjectURL(vcardBlob)
    const response = await UpdateDashboardAPI('save', cardDetailsData?.id!)
    setvcard(vcardURL)

    const downloadLink = document.createElement('a')
    downloadLink.href = vcardURL
    downloadLink.setAttribute('download', 'vCard.vcf') 
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  useEffect(() => {
    handleDownloadContact()
  }, [cardDetailsData])
  return (
    <div
      className='card p-0 mt-2'
      style={{
        // position: 'relative',
        zIndex: 0,
        backgroundColor: styleconfig?.background_color,
        color: styleconfig?.font_color,
        backgroundImage: `url(${backgroundImgUrl + styleconfig?.background_image})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        fontFamily: styleconfig?.font_style,
      }}
    >
      <div
        style={{
          // position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: 1,
          backdropFilter: `blur(${styleconfig?.background_blur}px)`,
        }}
      >
        <div
          // src='https://images.pexels.com/photos/1323206/pexels-photo-1323206.jpeg?cs=srgb&dl=pexels-mixu-1323206.jpg&fm=jpg'
          className='card-img-top'
          style={{
            height: styleconfig?.cover_image !== null ? styleconfig?.cover_image_height + 'px' : 0,
            backgroundImage: `url(${coverImageUrl + styleconfig?.cover_image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <div style={{position: 'relative'}}>
          {' '}
          <button
            style={{
              position: 'absolute', // Enable absolute positioning on the button
              top: '10px', // Adjust top distance as needed
              right: '10px',
              backgroundColor: 'transparent', // Remove button background color
              border: 'none', // Remove button border
              cursor: 'pointer',
              zIndex: 1, // Adjust right distance as needed
            }}
            onClick={() => {
              if (navigator.share) {
                navigator
                  .share({
                    title: document.title,
                    // text: cardDetailsData?.first_name + ' || ' + cardDetailsData?.designation,
                    url: window.location.href,
                  })
                  .then(() => console.log('Webpage shared successfully.'))
                  .catch((error) => console.error('Error sharing the webpage:', error))
              } else {
                console.log('Web Share API not supported.')
              }
            }}
          >
            {/* <?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools --> */}
            <svg
              width='30px'
              height='30px'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M1 18.5088C1 13.1679 4.90169 8.77098 9.99995 7.84598V5.51119C9.99995 3.63887 12.1534 2.58563 13.6313 3.73514L21.9742 10.224C23.1323 11.1248 23.1324 12.8752 21.9742 13.7761L13.6314 20.2649C12.1534 21.4144 10 20.3612 10 18.4888V16.5189C7.74106 16.9525 5.9625 18.1157 4.92778 19.6838C4.33222 20.5863 3.30568 20.7735 2.55965 20.5635C1.80473 20.3511 1.00011 19.6306 1 18.5088ZM12.4034 5.31385C12.2392 5.18613 11.9999 5.30315 11.9999 5.51119V9.41672C11.9999 9.55479 11.8873 9.66637 11.7493 9.67008C8.09094 9.76836 4.97774 12.0115 3.66558 15.1656C3.46812 15.6402 3.31145 16.1354 3.19984 16.6471C3.07554 17.217 3.00713 17.8072 3.00053 18.412C3.00018 18.4442 3 18.4765 3 18.5088C3.00001 18.6437 3.18418 18.6948 3.25846 18.5822C3.27467 18.5577 3.29101 18.5332 3.30747 18.5088C3.30748 18.5088 3.30746 18.5088 3.30747 18.5088C3.63446 18.0244 4.01059 17.5765 4.42994 17.168C4.71487 16.8905 5.01975 16.6313 5.34276 16.3912C7.05882 15.1158 9.28642 14.3823 11.7496 14.3357C11.8877 14.3331 12 14.4453 12 14.5834V18.4888C12 18.6969 12.2393 18.8139 12.4035 18.6862L20.7463 12.1973C20.875 12.0973 20.875 11.9028 20.7463 11.8027L12.4034 5.31385Z'
                fill={styleconfig?.font_color}
              />
            </svg>
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent:
              styleconfig?.profile_position === 'left'
                ? 'left'
                : styleconfig?.profile_position === 'right'
                ? 'right'
                : 'center',
          }}
        >
          {styleconfig?.profile_image && (
            <div
              // src='https://cdn.pixabay.com/photo/2015/03/03/08/55/portrait-657116__340.jpg'
              // className='profi'

              style={{
                borderRadius: '50%',
                // backgroundImage: new URL('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvp5RZg2cdXiaEMaKf0yWp-4B7hQzfVJJJYKCmf_Dl&s') as any,
                // background-image: url('path/to/profile/photo.jpg');
                backgroundSize: 'cover',
                backgroundImage: `url(${profileImageUrl + styleconfig?.profile_image})`,
                borderColor: 'white',
                backgroundPosition: 'center',
                border: '3px solid white',
                marginTop: styleconfig?.cover_image
                  ? '-50px'
                  : '50px' /* To center the profile picture */,
                height: 130,
                width: 130,
                marginRight: styleconfig?.profile_position === 'right' ? '20px' : '0',
                marginLeft: styleconfig?.profile_position === 'left' ? '20px' : '0',
                /* To add some space between the profile and cover pictures */
                // marginRight: styleconfig.profilePostion === 'right'? '20px': '0px',
              }}
            />
          )}
        </div>
        <div
          className={
            styleconfig?.profile_position === 'center'
              ? 'card-body  text-center'
              : styleconfig?.profile_position === 'right'
              ? 'card-body text-end'
              : 'card-body'
          }
        >
          <h3 className='fs' style={{color: styleconfig?.font_color}}>
            {cardDetailsData?.company_name}
          </h3>
          <h5 className='fw-light' style={{color: styleconfig?.font_color}}>
            {cardDetailsData?.first_name} {cardDetailsData?.middle_name}{' '}
            {cardDetailsData?.last_name}
          </h5>
          <h5 className=' fw-light fs-6' style={{color: styleconfig?.font_color}}>
            {cardDetailsData?.designation}
          </h5>
          <div
          // className={styleconfig.aboutcardBackground ? 'p-3 shadow rounded-3' : ' p-3'}
          // style={{
          //   background: styleconfig.
          //     ? styleconfig.aboutCardbackgroundColor
          //     : 'none',
          // }}
          >
            <p className='card-text'>{cardDetailsData?.about}</p>
          </div>
          <div
            className={
              styleconfig?.profile_position === 'left'
                ? 'row row-cols-5 justify-content-start g-1 p-5'
                : 'row row-cols-5 justify-content-center g-1 p-5'
            }
            style={{width: '100%'}}
          >
            {cardDetailsData?.website_link && (
              <a
                href={
                  cardDetailsData.website_link.includes('https://')
                    ? cardDetailsData.website_link
                    : 'https://' + cardDetailsData.website_link
                }
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='m-2'
                  src={toAbsoluteUrl(
                    '/media/svg/social-logos/2357362_colored_high quality_social_social media_square_icon.png'
                  )}
                  alt='internet'
                  style={{
                    borderRadius: '7px',
                    width: '35px',
                  }}
                />
              </a>
            )}
            {cardDetailsData?.whats_app_no && (
              
                <button style={{
                  background:'transparent',
                  outline:'none',
                  border:'none',
                  width:'fit-content',
                  height:'100%',

                  
                }}
                onClick= {whatsapp_delivery}
                >
                <img
                  className='m-2'
                  src={toAbsoluteUrl(
                    '/media/svg/social-logos/4102606_applications_media_social_whatsapp_icon.png'
                  )}
                  alt={'whatsapp'}
                  style={{width: '35px'}}
                />
                </button>
              
            )}
            {socialMedia?.map((social) => SocialIcons(social,cardID!))}
          </div>
          <div className='row row-cols-1 justify-content-center'>
            {cardDetailsData?.address?.length! > 0 && (
              <div
                className=' p-1 m-1 mb-2 col-10 rounded'
                style={{
                  backgroundColor:
                    styleconfig?.button_background == 0 ? styleconfig.button_color : 'none',

                  color: styleconfig?.font_color,
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
                }}
              >
                <div className='row items-center'>
                  {/* <img
                  className=''
                  src={toAbsoluteUrl('/media/svg/location-sign-svgrepo-com.svg')}
                  alt='fb'
                  height={20}
                  width={20}
                /> */}
                  <svg
                    height={20}
                    width={20}
                    fill='white'
                    version='1.1'
                    id='Capa_1'
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    // width='800px'
                    // height='800px'
                    viewBox='0 0 395.71 395.71'
                    xmlSpace='preserve'
                  >
                    <g>
                      <path
                        d='M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
		c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
		C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191
		c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z'
                      />
                    </g>
                  </svg>
                  <a href={cardDetailsData?.address?.at(0)?.address_link} style={{fontSize: 10}}>
                    <p
                      style={{fontSize: 10, color: styleconfig?.button_text_color}}
                      className=' mb-2 text-center '
                    >
                      {cardDetailsData?.address?.at(0)?.address_line1} &nbsp;
                      {cardDetailsData?.address?.at(0)?.city} &nbsp;
                      {cardDetailsData?.address?.at(0)?.state} &nbsp;
                    </p>
                  </a>
                </div>
              </div>
            )}
          </div>

          <div className='row row-cols-2 justify-content-center'>
            <div
              className='p-1 mb-2 m-1 col-10 rounded'
              style={{
                backgroundColor:
                  styleconfig?.button_background == 0 ? styleconfig.button_color : 'none',
                color: styleconfig?.font_color,
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
              }}
            >
              <a
                href={`mailto:${cardDetailsData?.email}`}
                target='_blank'
                className='font-semibold'
                rel='noreferrer'
              >
                <div className='row items-center'>
                  {icons('/media/svg/social-logos/email.svg')}
                  <p
                    style={{fontSize: 10, color: styleconfig?.button_text_color}}
                    className=' mb-2 text-center '
                  >
                    {cardDetailsData?.email}
                  </p>
                </div>
              </a>
            </div>

            <div className='row justify-content-center w-100'>
              {cardDetailsData?.mobile_no && (
                <div
                  className={
                    cardDetailsData?.alternet_no
                      ? 'p-1 m-1 mb-2 width__class rounded'
                      : 'p-1 m-1 mb-2 save__width__class rounded'
                  }
                  style={{
                    backgroundColor:
                      styleconfig?.button_background == 0 ? styleconfig.button_color : 'none',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
                    color: styleconfig?.font_color,
                  }}
                >
                  <a
                    href={`tel:${cardDetailsData?.country_code}${cardDetailsData?.mobile_no}`}
                    className='font-semibold '
                  >
                    <div className='row items-center '>
                      {icons('/media/svg/social-logos/phone-call.svg')}
                      <p
                        style={{fontSize: 10, color: styleconfig?.button_text_color}}
                        className=' mb-2 text-center '
                      >
                        Call {cardDetailsData?.country_code}
                        {cardDetailsData?.mobile_no}
                      </p>
                    </div>
                  </a>
                </div>
              )}

              {cardDetailsData?.alternet_no && (
                <div
                  className={
                    cardDetailsData?.mobile_no
                      ? 'p-1 m-1 mb-2 width__class rounded'
                      : 'p-1 m-1 mb-2 save__contact rounded'
                  }
                  style={{
                    backgroundColor:
                      styleconfig?.button_background == 0 ? styleconfig.button_color : 'none',
                    color: styleconfig?.font_color,
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
                  }}
                >
                  <a
                    href={`tel:${cardDetailsData?.country_code}${cardDetailsData?.alternet_no}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className=' row items-center'>
                      {icons('/media/svg/social-logos/phone-call.svg')}
                      <p
                        style={{fontSize: 10, color: styleconfig?.button_text_color}}
                        className=' mb-2 text-center '
                      >
                        Alt No. {cardDetailsData?.alternet_no}
                      </p>
                    </div>
                  </a>
                </div>
              )}

              <div
                className={
                  cardDetailsData?.alternet_no
                    ? 'p-1 m-1 mb-2 save__contact rounded'
                    : cardDetailsData?.mobile_no
                    ? 'p-1 m-1 mb-2 save__width__class rounded'
                    : 'p-1 m-1 mb-2 save__contact rounded'
                }
                style={{
                  backgroundColor:
                    styleconfig?.button_background == 0 ? styleconfig.button_color : 'none',
                  color: styleconfig?.font_color,
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.7)',
                }}
              >
                <button onClick={handleDownloadContact}
                style={
                  {
                    border:"none",
                    backgroundColor:"transparent",
                    outline:"none",
                    width:"100%"
                  }
                }>
                  <div className=' row items-center'>
                    {icons('/media/svg/social-logos/file-download.svg')}
                    <p
                      style={{fontSize: 10, color: styleconfig?.button_text_color}}
                      className=' mb-2 text-center '
                    >
                      Save Contact
                    </p>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function SocialIcons(socialMedia: SocialLink,id:any) {
  return (
    <button
      style={{
        background: 'transparent',
        border: 'none',
        outline: 'none',
      }}
      onClick={async () => UpdateDashboardAPI(socialMedia.social_type?.name!, id)}
    >
      <a href={socialMedia.social_link} target='_blank' rel='noreferrer'>
        {' '}
        <img
          className='m-2'
          src={socialMediaURL + socialMedia.social_type?.logo}
          alt={socialMedia.social_type?.name}
          style={{width: '35px'}}
        />
      </a>
    </button>
  )
}
export default CardDetails3
