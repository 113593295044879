import React, {useEffect, useState} from 'react'
import Payment from '../../cardDetails/Payment/Payment'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './template02.css'
import {constants} from 'buffer'
import useApiHook from '../../../../app/modules/hook/useApiHook'
import {
  CARD_BG_IMAGE_URL,
  COVER_IMAGE_URL,
  PROFILE_IMAGE_URL,
  SOCIAL_MEDIA_URL,
} from '../../../modules/helpers/imageUrl'
import {UpdateDashboardAPI} from './updateDashboardAPI'

const socialMediaURL = SOCIAL_MEDIA_URL

//product interface
interface Product {
  card_id?: number
  category_name?: string
  category_pic?: string
  price?: number
  created_at?: string
  enquiry_link?: string | null
  visit_link?: string | null
  id?: number
  select_format?: string
  updated_at?: string
}

interface Gallery {
  id?: number
  card_id?: number
  image_file?: string
  created_at?: string
  updated_at?: string
}
interface BankDetail {
  id?: number
  card_id?: number
  bank_name?: string
  account_holder_name?: string
  account_number?: string
  ifsc_code?: string
  gst_no?: string
  created_at?: string
  updated_at?: string
}

interface Address {
  id?: number
  card_id?: number
  address_line1?: string
  address_line2?: string
  country?: string
  state?: string
  city?: string
  pin_code?: string
  type?: string
  created_at?: string
  updated_at?: string
  address_link?: string
}

interface PaymentDetails {
  id?: number
  card_id?: number
  platform_name?: string
  upi_id?: string
  qr_code?: string
  created_at?: string
  updated_at?: string
}

export interface SocialLink {
  id?: number
  card_id?: number
  social_type_id?: number
  social_link?: string
  created_at?: string
  updated_at?: string
  social_type?: SocialType
}

export interface SocialType {
  id?: number
  name?: string
  logo?: any
  status?: number
  created_at?: any
  updated_at?: any
}

interface Template02Props {
  id?: number
  first_name?: string
  last_name?: string
  about?: string
  company_name?: string
  designation?: string
  profile_image?: string
  background_image?: string
  background_color?: string
  bg_image?: string
  font_color?: string
  font_style?: string
  button_background_color?: string
  button_font_color?: string
  mobile_no?: string
  country_code?: string
  whatsapp_no?: string
  email?: string
  website_link?: string
  qr_code_image?: string
  product?: Product[]
  galleries?: Gallery[]
  payment?: PaymentDetails[]
  address?: Address[]
  socialMedia?: SocialLink[]
}

const Template02: React.FC<Template02Props> = ({
  id,
  first_name,
  last_name,
  about,
  company_name,
  designation,
  profile_image,
  background_image,
  background_color,
  bg_image,
  font_color,
  font_style,
  button_background_color,
  button_font_color,
  country_code,
  mobile_no,
  whatsapp_no,
  email,
  website_link,
  qr_code_image,
  product,
  galleries,
  address,
  payment,
  socialMedia,
}) => {
  //API Credibility
  const BASE_URL = 'https://card.tap-savvy.com/'
  const API_BASE_URL = process.env.REACT_APP_API_URL
  const token = localStorage.getItem('token')
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${token}`)

  const {onPost} = useApiHook()

  // PROFILE IMAGE
  const profileIMG_base_url =
    'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/profile_image/'

  const default_profile_image = `https://t4.ftcdn.net/jpg/00/64/67/27/360_F_64672736_U5kpdGs9keUll8CRQ3p3YaEv2M6qkVY5.jpg`
  let profile_image_url = ''
  if (profile_image) {
    profile_image_url = profileIMG_base_url + profile_image
  }

  async function whatsapp_delivery() {
    whatsapp_no &&
      await UpdateDashboardAPI('whatsapp', id!)
      window.open(`https://wa.me/${country_code}${whatsapp_no}?text=hi there`, '_blank')
  }


  //Social media
  function SocialIcons(socialMedia: SocialLink) {
    return (
      <button
        style={{
          background: 'transparent',
          border: 'none',
          outline: 'none',
        }}
        onClick={async () => UpdateDashboardAPI(socialMedia.social_type?.name!, id!)}
      >
        <a href={socialMedia.social_link} target='_blank' rel='noreferrer'>
          {' '}
          <img
            className='m-2'
            src={socialMediaURL + socialMedia.social_type?.logo}
            alt={socialMedia.social_type?.name}
            style={{width: '35px'}}
          />
        </a>
      </button>
    )
  }

  // COVER IMAGE
  const coverIMG_base_url =
    'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/template/cover_images/'
  const coverIMG__url = `${coverIMG_base_url}${background_image}`
  let default_cover_img =
    'https://www.solidbackgrounds.com/images/950x350/950x350-gray-solid-color-background.jpg'

  //CONNECTION BOX MOBILE

  useEffect(() => {
    const mobile_icon = document.getElementsByClassName('connection_phone')[0]
    const mobileno = {mobile_no}
    function makeCall() {
      window.location.href = `tel:${mobile_no}`
    }

    if (mobileno && mobile_icon) {
      mobile_icon.addEventListener('click', makeCall)
    }
  })

  //CONNECTION BOX EMAIL

  useEffect(() => {
    const email_icon = document.getElementsByClassName('connection_email')[0]
    const emailid = {email}
    function makeemail() {
      const mailtoLink = `mailto:${emailid.email}`
      window.location.href = mailtoLink
    }
    if (emailid && emailid.email !== undefined && email_icon) {
      email_icon.addEventListener('click', makeemail)
    }
  })

  //CONNECTION BOX WEBSITE

  //Website URL Link
  const [link, setLink] = useState(website_link ?? '')
  const prependHttps = (url: string) => {
    return url.startsWith('https://') ? url : `https://${url}`
  }
  const newURL = prependHttps(link)
  function website_redirect() {
    window.open(newURL, '_blank')
  }

  const [whatsappNo, setWhatsappNo] = useState('')
  const card_url = window.location.href

  //CONNECTION BOX HASHTAG

  //CONNECTION BOX LOCATION

  //Working with Address

  function verifyAddress() {
    if ((address ?? [])[0]?.address_link || (address ?? [])[0]?.address_line1) {
      return true
    } else {
      return false
    }
  }

  function openmap() {
    if (address![0].address_link) {
      // If address_link is provided, open the map link
      window.open(address![0].address_link, '_blank')
    } else {
      // If address_link is not provided, generate a new address and perform a Google Maps search
      const newAddress = `${address![0].address_line1 || ''}, ${address![0].address_line2 || ''}, ${
        address![0].city || ''
      }, ${address![0].state || ''}, ${address![0].country || ''} ${address![0].pin_code || ''}`
      if (newAddress) {
        const googleMapsSearchLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          newAddress
        )}`
        window.open(googleMapsSearchLink, '_blank')
      }
    }
  }

  //QR IMAGE

  const qr_image_BASE_URL =
    'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/card_qr_code/'
  const qr_image = qr_code_image
  const qr_image_URL = `${qr_image_BASE_URL}${qr_image}`

  const downloadQR = () => {
    const link = document.createElement('a')
    link.href = qr_image_URL
    link.download = 'qr_code_image.png'
    link.click()
    UpdateDashboardAPI('qrDownload', id!)
  }

  //ADD TO CONTACTS
  const [vcard, setvcard] = useState<string>('')
  const downloadContact = async () => {
    const contact = {
      firstName: first_name,
      lastName: last_name ? last_name : '',
      phone: mobile_no,
      whatsapp: whatsapp_no ? whatsapp_no : '',
      email: email,
      company: company_name ? company_name : '',
      designation: designation ? designation : '',
      address:
        address?.length! > 0
          ? address?.at(0)?.address_line1! +
            (address?.at(0)?.city ? ' ' + address?.at(0)?.city : '') +
            (address?.at(0)?.country ? ' ' + address?.at(0)?.country : '') +
            (address?.at(0)?.pin_code ? ' ' + address?.at(0)?.pin_code : '')
          : '',
    }

    const vcard = `BEGIN:VCARD
VERSION:3.0
PRODID:-//Apple Inc.//Mac OS X 10.14.1//EN
N:${contact.lastName};${contact.firstName};;;;
FN:${contact.firstName} ${contact.lastName}
ORG:${contact.company}
TEL;TYPE=CELL;TYPE=VOICE:${contact.phone}
TEL;TYPE=WHATSAPP:${contact.whatsapp}
EMAIL:${contact.email}
ADR;TYPE=WORK:;;${contact.address};;;;
TITLE:${contact.designation}
END:VCARD`

    const vcardBlob = new Blob([vcard], {type: 'text/vcard'})
    const vcardURL = URL.createObjectURL(vcardBlob)
    setvcard(vcardURL)
    UpdateDashboardAPI('save', id!)
  }
  // SHARE BUTTON
  function shareWithNative() {
    if (navigator.share) {
      navigator
        .share({
          title: 'Share this page',
          url: window.location.href,
        })
        .catch((error) => console.error('Sharing failed:', error))
    } else {
      alert('Native sharing is not supported in your browser.')
    }
  }

  //Working with Products
  const redirectToNewLink = (link: string | null | undefined) => {
    const sanitizedLink = link ?? ''
    let newLink
    if (!sanitizedLink.startsWith('https://')) {
      newLink = `https://${sanitizedLink}`
    } else {
      newLink = sanitizedLink
    }
    window.open(newLink, '_blank', 'noopener,noreferrer')
  }

  //ENQUIRY FUNCTION
  const enquiryBTN = async (name: string, format: string, image: string, id: number) => {
    const message = `Hi, I would love to know about your ${format} ${name}`
    const encodedMessage = encodeURIComponent(message)
    const imageURL = `https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/category_pic/${image}`
    const encodedImage = encodeURIComponent(imageURL)

    const whatsappLink = `https://wa.me/${whatsapp_no}?text=${encodedMessage}&attachment=${encodedImage}`
    window.open(whatsappLink, '_blank')
    const response = await onPost(`/product-enquiries`, {product_id: `${id}`})
  }

  // Gallery Enlarge option

  const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null)

  const handleGalleryItemClick = (index: number) => {
    const imagefile = galleries![index].image_file
    const url = 'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/galleries/'
    const imageURL = `${url}${imagefile}`
    setModalContent(
      <img
        src={imageURL}
        alt={`Gallery Image ${index}`}
        className='img-fluid'
        style={{maxHeight: '100%', maxWidth: '100%'}}
      />
    )
    setSelectedImageIndex(index)
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const handleBoxClick = () => {
    setIsModalOpen(true)
  }

  return (
    <div
      className='main_body'
      style={{
        background: '#151521',
      }}
    >
      <div
        className='card_container '
        style={{
          background: `${background_color ? background_color : '#000'}`,
          color: `${font_color ? font_color : '#000'}`,
          fontFamily: `${font_style ? font_style : 'Helvetica'}`,
        }}
      >
        <div
          className='cover_image_container'
          style={{
            backgroundImage: `url(${background_image ? coverIMG__url : default_cover_img})`,
          }}
        >
          <div className='name-container'>
            {first_name ? first_name : ' '} <br />
            {last_name ? last_name : ' '}
          </div>
        </div>

        <div
          className='about-container'
          style={{
            background: `${background_color ? background_color : '#000'}`,
          }}
        >
          <div className='profile_info'>
            <div
              className='profile_image'
              style={{
                backgroundImage: `url(${
                  profile_image ? profile_image_url : default_profile_image
                })`,
              }}
            ></div>
            <div className='profile_designation'>
              <div className='profile_company_name'>{company_name ? company_name : ' '}</div>
              <div className='profile_job_title'>{designation ? designation : ' '}</div>
            </div>
          </div>

          <div className='profile_connections'>
            {mobile_no ? (
              <div
                className='connection_box connection_phone d-flex justify-content-center align-items-center'
                style={{
                  background: `${button_background_color ? button_background_color : '#fff'}`,
                }}
              >
                <i
                  className='fa-solid fa-mobile-screen'
                  style={{
                    fontSize: '25px',
                    color: `${button_font_color ? button_font_color : '#fff'}`,
                  }}
                ></i>
              </div>
            ) : (
              ''
            )}

            {email ? (
              <div
                className='connection_box connection_email d-flex justify-content-center align-items-center'
                style={{
                  background: `${button_background_color ? button_background_color : '#fff'}`,
                }}
              >
                <i
                  className='fa-regular fa-envelope'
                  style={{
                    fontSize: '25px',
                    color: `${button_font_color ? button_font_color : '#fff'}`,
                  }}
                ></i>
              </div>
            ) : (
              ''
            )}

            {website_link ? (
              <div
                className='connection_box connection_website d-flex justify-content-center align-items-center'
                style={{
                  background: `${button_background_color ? button_background_color : '#fff'}`,
                }}
              >
                <button
                  className='btn h-100 w-100 d-flex justify-content-center align-items-center'
                  style={{backgroundColor: 'transparent', overflow: 'hidden'}}
                  onClick={website_redirect}
                >
                  <i
                    className='fa-solid fa-globe ms-2 '
                    style={{
                      fontSize: '25px',
                      color: `${button_font_color ? button_font_color : '#fff'}`,
                    }}
                  ></i>
                </button>
              </div>
            ) : (
              ``
            )}

            {whatsapp_no ? (
              <div
                className='connection_box connection_website d-flex justify-content-center align-items-center'
                style={{
                  background: `${button_background_color ? button_background_color : '#fff'}`,
                }}

              >
                <button
                  className='btn h-100 w-100 d-flex justify-content-center align-items-center'
                  style={{backgroundColor: 'transparent', overflow: 'hidden'}}
                  onClick={whatsapp_delivery}
                >
                  <i
                    className='fa-brands fa-whatsapp fw-bold ms-2 '
                    style={{
                      fontSize: '25px',
                      color: `${button_font_color ? button_font_color : '#fff'}`,
                    }}
                  ></i>
                </button>
              </div>
            ) : (
              ``
            )}

            <div
              className='d-flex justify-content-center align-items-center'
              style={{
                background: `${button_background_color ? button_background_color : '#fff'}`,
              }}
            >
              <button
                type='button'
                className='btn  w-100 h-100 d-flex justify-content-center align-items-center' // Bootstrap class for 100% width
                data-bs-toggle='modal'
                data-bs-target='#staticBackdropSocial'
                style={{
                  background: 'transparent',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <i
                  className='fa-solid fa-hashtag ms-2'
                  style={{
                    fontSize: '25px',
                    color: `${button_font_color ? button_font_color : '#fff'}`,
                  }}
                ></i>
              </button>
            </div>

            {verifyAddress() ? (
              <div
                className='connection_box connection_address d-flex justify-content-center align-items-center'
                style={{
                  background: `${button_background_color ? button_background_color : '#fff'}`,
                }}
                onClick={openmap}
              >
                <i
                  className='fa-solid fa-location-dot'
                  style={{
                    fontSize: '25px',
                    color: `${button_font_color ? button_font_color : '#fff'}`,
                  }}
                ></i>
              </div>
            ) : (
              ''
            )}
          </div>
          {about ? (
            <div className='profile_about'>
              <div className='profile_about_title'>About</div>
              <div className='profile_about_content'>{about}</div>
            </div>
          ) : (
            <div></div>
          )}
          {/* <div className='profile_about'>
            <div className='profile_about_title'>About Me</div>
            <div className='profile_about_content'>{about}</div>
          </div> */}
        </div>

        {/* Whatsapp Share section  */}

        <div
          className='card p-2 border'
          style={{
            background: `${background_color ? background_color : '#000'}`,
            color: `${font_color ? font_color : '#000'}`,
            fontFamily: `${font_style ? font_style : 'Helvetica'}`,
          }}
        >
          <div className='card-body rounded-3 p-4'>
            <h1 className='card-title text-center'>Connect On Whatsapp</h1>
            <div
              className='row'
              style={{padding: '10px', boxSizing: 'border-box', overflow: 'hidden'}}
            >
              <div className='col-8'>
                <input
                  value={whatsappNo}
                  onChange={(e) => setWhatsappNo(e.target.value)}
                  className='form-control'
                  placeholder='Enter number with country code'
                  style={{marginTop: '10px', background: '#938e87', color: '#fff'}}
                />
              </div>
              <div className='col-4'>
                <button
                  // style={{background: orderDetails?.template?.button_color}}
                  style={{background: 'none'}}
                  className={'btn  w-100 ' + (whatsapp_no!.length < 10 && ' disabled')}
                >
                  <a
                    target='blank'
                    href={`https://wa.me/${whatsappNo}?text=${card_url}`}
                    style={{
                      textDecoration: 'none',
                    }}
                  >
                    <div
                      style={{
                        width: '70px',
                        height: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#25d366',
                        border: 'none',
                        padding: '20px 50px',
                        borderRadius: '12px',
                        marginTop: '0',
                        color: '#ffffff',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                        marginRight: '20px',
                      }}
                    >
                      {/* Text content */}
                      <span
                        style={{
                          marginRight: '5px',
                          fontFamily: 'Arial, sans-serif',
                          fontWeight: 'lighter',
                        }}
                      >
                        Share
                      </span>

                      {/* Image */}
                      <img
                        src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/2044px-WhatsApp.svg.png'
                        alt='WhatsApp Icon'
                        style={{width: '20px', height: '20px'}}
                      />
                    </div>
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className='card_features_container'
          style={{
            background: `${background_color ? background_color : '#000'}`,
          }}
        >
          {/* <h1 style={{color:'white'}}>HELLO WORLD</h1> */}

          <div className='card_features'>
            <ul
              className='card-features-nvigation nav nav-pills mb-3'
              id='pills-tab'
              role='tablist'
            >
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link product-button active'
                  id='pills-home-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-home'
                  type='button'
                  role='tab'
                  aria-controls='pills-home'
                  aria-selected='true'
                >
                  Products
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link gallery-button'
                  id='pills-profile-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-profile'
                  type='button'
                  role='tab'
                  aria-controls='pills-profile'
                  aria-selected='false'
                >
                  Gallery
                </button>
              </li>
              <li className='nav-item' role='presentation'>
                <button
                  className='nav-link payments-button'
                  id='pills-contact-tab'
                  data-bs-toggle='pill'
                  data-bs-target='#pills-contact'
                  type='button'
                  role='tab'
                  aria-controls='pills-contact'
                  aria-selected='false'
                >
                  Payments
                </button>
              </li>
            </ul>

            <div className='tab-content' id='pills-tabContent'>
              {/* products tab */}

              {product ? (
                <div
                  className='tab-pane fade show active'
                  id='pills-home'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'
                  style={{
                    padding: '10px',
                  }}
                >
                  <div className='row'>
                    {product &&
                      product.map((product_object, index) => (
                        <div key={index} className='col-6 mb-3'>
                          <div className='image-gallery-container'>
                            <div
                              style={{
                                backgroundImage: `url('https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/category_pic/${product_object.category_pic}')`,
                              }}
                            ></div>
                            <div>
                              <h4 style={{color: 'black'}}>
                                {product_object.category_name
                                  ? product_object.category_name
                                  : 'A Product'}
                              </h4>
                              <p>
                                {product_object.select_format
                                  ? product_object.select_format
                                  : 'Lorem Ipusm'}
                              </p>
                              <div>
                                {product_object.price ? `Price: ${product_object.price}` : ''}
                              </div>
                            </div>

                            <div>
                              {product_object.visit_link ? (
                                <div
                                  className='border'
                                  onClick={() => redirectToNewLink(product_object.visit_link)}
                                >
                                  View
                                </div>
                              ) : (
                                <div>View</div>
                              )}

                              {/* <div className='product_enquiry' onClick={enquiryBTN(product_object.category_name!,product_object.select_format!,product_object.category_pic!)}>Enquiry</div> */}
                              <div
                                className='product_enquiry border'
                                onClick={() =>
                                  enquiryBTN(
                                    product_object.category_name!,
                                    product_object.select_format!,
                                    product_object.category_pic!,
                                    product_object.id!
                                  )
                                }
                              >
                                Enquiry
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ) : (
                <div
                  className='tab-pane fade show active'
                  id='pills-home'
                  role='tabpanel'
                  aria-labelledby='pills-home-tab'
                  style={{
                    padding: '10px',
                  }}
                >
                  {/* <div style={{height:'40px', width:'40px', background:'blue'}}>

                 </div> */}
                </div>
              )}

              {/* gallery tab  */}
              <div
                className='tab-pane fade'
                id='pills-profile'
                role='tabpanel'
                aria-labelledby='pills-profile-tab'
              >
                <div className='row'>
                  {galleries!.map((gallery_object, index) => (
                    <div
                      key={index}
                      data-index={index.toString()}
                      className='col-4 border gallery_container'
                      style={
                        gallery_object.image_file
                          ? {
                              backgroundImage: `url('https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/galleries/${gallery_object.image_file}')`,
                            }
                          : {background: '#f1f1f1', cursor: 'not-allowed'}
                      }
                      data-bs-toggle='modal'
                      data-bs-target='#galleryModal'
                      onClick={() => handleGalleryItemClick(index)}
                    ></div>
                  ))}
                </div>
              </div>

              {/* payment tab  */}

              <div
                className='tab-pane fade'
                id='pills-contact'
                role='tabpanel'
                aria-labelledby='pills-contact-tab'
              >
                <Payment paymentDetails={payment} />
              </div>
            </div>
          </div>
        </div>

        <div className='card_footer'>
          <div className='footer_left'>
            <div
              className='d-flex justify-content-center align-items-center'
              style={{background: `${button_background_color ? button_background_color : '#fff'}`}}
            >
              <button
                type='button'
                className='btn  w-100 h-100 d-flex justify-content-center align-items-center'
                data-bs-toggle='modal'
                data-bs-target='#staticBackdrop'
                style={{
                  background: 'transparent',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <i
                  className='fa-solid fa-qrcode ms-2'
                  style={{
                    fontSize: '25px',
                    color: `${button_font_color ? button_font_color : '#000'}`,
                  }}
                ></i>
              </button>
            </div>
            <div
              className='d-flex justify-content-center align-items-center'
              style={{
                background: `${button_background_color ? button_background_color : '#fff'}`,
              }}
            >
              {''}
              <button
                type='button'
                className='btn w-100 h-100 d-flex justify-content-center align-items-center '
                style={{
                  background: 'transparent',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                onClick={() => {
                  if (navigator.share) {
                    navigator
                      .share({
                        title: document.title,
                        url: window.location.href,
                      })
                      .then(() => console.log('Webpage shared successfully.'))
                      .catch((error) => console.error('Error sharing the webpage:', error))
                  } else {
                    console.log('Web Share API not supported.')
                  }
                }}
              >
                <i
                  className='fa-solid fa-arrow-up-from-bracket ms-2'
                  style={{
                    fontSize: '25px',
                    color: `${button_font_color ? button_font_color : '#000'}`,
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='footer_right'>
            <div
              className='footer-right-container'
              style={{
                background: `${button_background_color ? button_background_color : '#fff'}`,
                color: `${font_color ? font_color : '#000'}`,
                padding: '0',
              }}
            >
              <a
                href={vcard}
                download='test.vcf'
                onClick={downloadContact}
                className='d-flex gap-2 justify-content-center align-items-center p-4'
              >
                <div
                  style={{
                    color: '#000000',
                  }}
                >
                  Add to Contacts
                </div>
                <div
                  style={{
                    color: '#000000',
                  }}
                >
                  +
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* QR MODAL  */}
      <div
        className='modal fade'
        id='staticBackdrop'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                QR IMAGE
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <img src={qr_image_URL} alt='QR Image' style={{width: '100%'}} />
            </div>
            <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary download_QR_btn'
                onClick={downloadQR}
              >
                Download QR
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Gallery Modal  */}
      <div
        className='modal fade'
        id='galleryModal'
        tabIndex={-1}
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body d-flex justify-content-center'>{modalContent}</div>
          </div>
        </div>
      </div>

      {/* Social Media */}
      <div
        className='modal fade'
        id='staticBackdropSocial'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        aria-labelledby='staticBackdropLabelSocial'
        aria-hidden='true'
      >
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabelSocial'>
                Social Media
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>{socialMedia?.map((social) => SocialIcons(social))}</div>
            {/* <div className='modal-footer'>
              <button
                type='button'
                className='btn btn-primary download_QR_btn'
                onClick={downloadQR}
              >
                Download QR
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Template02
