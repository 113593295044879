import React, {FC} from 'react'
import {OrderDetail, ProductService} from '../interfaces/cardDetails'
import { CATEGORY_IMAGE_URL } from '../../../modules/helpers/imageUrl'
interface props {
  productDetails?: ProductService[]
  orderDetails?: OrderDetail
  whatsappNo?:string
}

const ProductServices: FC<props> = ({productDetails, orderDetails, whatsappNo}) => {
  const imageRoute = CATEGORY_IMAGE_URL
  const style = orderDetails?.template

  return (
    <div
      className='card'
      style={{backgroundColor: style?.background_color, fontFamily: style?.font_style}}
    >
      <h1 className='text-center fw-bold p-3' style={{color:style?.font_color}}>Products And Services</h1>{' '}
      <>{productDetails?.map((product, index) => newFunction(product, index))}</>
      
    </div>
  )

  function newFunction(product: ProductService, id: number) {
    
    
    return (
      <div
        key={id}
        className='card mt-4 m-6  shadow-lg'
        style={{backgroundColor: style?.background_color, fontFamily: style?.font_style}}
      >
        {/* <div
          style={{
            height: '320px',
            maxHeight: '100%',
            width: 'auto',
          }}
        > */}
          {' '}
          <img
            src={imageRoute + product.category_pic}
            // style={{
            //   aspectRatio: 3 / 4,
            //   objectFit: 'cover',
            //   width: '100%',
            //   height: '100%',
            // }}
            className='card-img'
            alt='Product'
          />{' '}
        {/* </div> */}
        <div className='card-body'>
          <h3 className='text-center ' style={{color: style?.font_color}}>
            {product.category_name}
          </h3>
          {
            product.price?
            (
              <>
              <h3 className='text-center'>
                Price: {product.price}
              </h3>
              </>
            )
            :
            ''
          }
          {/* <p className='card-text'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur illo vero earum
            minus accusamus, quos amet suscipit ducimus dolore eveniet sapiente! Inventore aperiam
            similique dignissimos error iusto et corrupti nesciunt.
          </p> */}
          <div className='d-grid gap-2 d-md-flex justify-content-md-center'>
            {' '}
            {product.visit_link && <button
              className='btn rounded-pill  me-md-2 mb-2 mb-md-0  shadow'
              style={{
                backgroundColor: style?.button_background == 0 ? style.button_color : 'none',

                color: style?.button_text_color,
              }}
              type='button'
            >
              <a
                href={product.visit_link}
                target='_blank'
                style={{color: style?.button_text_color}}
                rel='noopener noreferrer'
              >
                View
              </a>
            </button>}{' '}
            <button
              className='btn rounded-pill  shadow'
              style={{
                backgroundColor: style?.button_background == 0 ? style.button_color : 'none',
                color: style?.font_color,
              }}
              type='button'
            >
              <a
                href={`https://api.whatsapp.com/send?phone=${whatsappNo}&text=Hello!%20I%20want%20to%20know%20more%20about%20your%20${product.select_format}%20:%20${product.category_name}`}
                target='_blank'
                style={{color: style?.button_text_color}}
                rel='noreferrer'
              >
                Enquiry
              </a>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default ProductServices
