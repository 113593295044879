const baseUrl = 'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/'

const appBaseUrl = 'https://tap-savvy-bucket.s3.ap-south-1.amazonaws.com/assets/uploads/'

// export const APPURL = 'https://api.tap-savvy.com/api'

export const APPURL = 'https://test-api.tap-savvy.com/api' 

export const COVER_IMAGE_URL = baseUrl + 'template/cover_images/'
export const PROFILE_IMAGE_URL = baseUrl + 'template/profile_image/'
export const SOCIAL_MEDIA_URL = baseUrl + 'social_type/logo/'
export const QRCODE_IMAGE_URL = baseUrl + 'qrcodes/'
export const GALLERY_IMAGE_URL = baseUrl + 'galleries/'
export const CATEGORY_IMAGE_URL = baseUrl + 'category_pic/'
export const CARD_BG_IMAGE_URL = baseUrl + 'template/background_images/'
export const PROFILE_URL_IMAGE = appBaseUrl + 'profile_pic/'
export const TICKET_URL_IMAGE = appBaseUrl + "ticket_images/"
